// const baseURL = 'https://localhost:44389/api/';
 const baseURL = 'https://api.dotregistration.co.in/api/';

export const LOGIN_URL = `${baseURL}Account/userlogin`;
export const CHANGE_PASSWORD = `${baseURL}Account/changepassword`;
export const GET_REGION_BY_STATE_ID = `${baseURL}Masters/getregionssbystateid`;
export const GET_REGION_BY_ID = `${baseURL}Masters/getregionbyid`;
export const ADD_UPDATE_REGION = `${baseURL}Masters/addupdateregion`;
export const GET_DISTRICT_BY_REGION_ID = `${baseURL}Masters/getdistrictsbyregionid`;
export const GET_DISTRICT_BY_ID = `${baseURL}Masters/getdistrictbyid`;
export const ADD_UPDATE_DISTRICT = `${baseURL}Masters/addupdatedistrict`;
export const GET_USERS = `${baseURL}Masters/getusers`;
export const GET_USER_BY_ID = `${baseURL}Masters/getuserbyid`;
export const ADD_UPDATE_USER = `${baseURL}Masters/addupdateuser`;
export const UPDATE_USER_STATUS = `${baseURL}Masters/updateuserstatus`;
export const GET_SITE_VISIT_CRITERIA_BY_TYPE = `${baseURL}Masters/getsitevisitcriteriabytype`;
export const GET_SITE_VISIT_CRITERIA_BY_ID = `${baseURL}Masters/getsitevisitcriteriabyid`;
export const ADD_UPDATE_SITE_VISIT_CRITERIA = `${baseURL}Masters/addupdatesitevisitcriteria`;
// export const GET_IMAGE_DATA = `${baseURL}Admin/getimagedata`;

export const GET_AGRO_TOUR_REGISTRATIONS = `${baseURL}Admin/getagrotourregistrationbysearch`;
export const UPDATE_AGRO_TOUR_REGISTRATION_VERIFICATION = `${baseURL}Admin/updateagrotourregistrationverification`;
export const UPDATE_AGRO_TOUR_DOCUMENT_VERIFICATION = `${baseURL}Admin/updateagrotourdocumentverification`;
export const GET_AGRO_TOUR_REGISTRATION_BY_ID = `${baseURL}Applicant/getagrotourregistrationbyid`;
export const GET_AGRO_TOUR_DOCUMENTS_BY_ID = `${baseURL}Applicant/getagrotourdocumentsbyid`;
export const ADD_AGRO_TOUR_SITE_VISIT_DETAILS = `${baseURL}Admin/addagrotoursitevisitdetails`;
export const DELETE_AGRO_TOUR_SITE_VISIT_DETAILS = `${baseURL}Admin/deleteagrotoursitevisitdetails`;
export const GET_AGRO_TOUR_SITE_VISIT_DETAILS = `${baseURL}Admin/getagrotoursitevisitcriteriabyid`;
export const GET_AGRO_CERTIFICATE_NUMBER = `${baseURL}Admin/getagrocertificatenumber`;

export const GET_ADVENTURE_TOUR_REGISTRATIONS = `${baseURL}Admin/getadventuretourregistrationbysearch`;
export const UPDATE_ADVENTURE_TOUR_REGISTRATION_VERIFICATION = `${baseURL}Admin/updateadventuretourregistrationverification`;
export const UPDATE_ADVENTURE_TOUR_DOCUMENT_VERIFICATION = `${baseURL}Admin/updateadventuretourdocumentverification`;
export const GET_ADVENTURE_TOUR_REGISTRATION_BY_ID = `${baseURL}Applicant/getadventuretourregistrationbyid`;
export const GET_ADVENTURE_TOUR_DOCUMENTS_BY_ID = `${baseURL}Applicant/getadventuretourdocumentsbyid`;
export const GET_ADVENTURE_TOUR_EQUIPMENTS_BY_ID = `${baseURL}Applicant/getadventuretourequipementsbyid`;
export const ADD_ADVENTURE_TOUR_SITE_VISIT_DETAILS = `${baseURL}Admin/addadventuretoursitevisitdetails`;
export const DELETE_ADVENTURE_TOUR_SITE_VISIT_DETAILS = `${baseURL}Admin/deleteadventuretoursitevisitdetails`;
export const GET_ADVENTURE_TOUR_SITE_VISIT_DETAILS = `${baseURL}Admin/getadventuretoursitevisitcriteriabyid`;
export const GET_ADVENTURE_CERTIFICATE_NUMBER = `${baseURL}Admin/getadventurecertificatenumber`;

export const GET_CARAVAN_REGISTRATIONS = `${baseURL}Admin/getcaravanregistrationbysearch`;
export const UPDATE_CARAVAN_REGISTRATION_VERIFICATION = `${baseURL}Admin/updatecaravanregistrationverification`;
export const UPDATE_CARAVAN_DOCUMENT_VERIFICATION = `${baseURL}Admin/updatecaravandocumentverification`;
export const GET_CARAVAN_REGISTRATION_BY_ID = `${baseURL}Applicant/getcaravanregistrationbyid`;
export const GET_CARAVAN_DOCUMENTS_BY_ID = `${baseURL}Applicant/getcaravandocumentsbyid`;
export const ADD_CARAVAN_SITE_VISIT_DETAILS = `${baseURL}Admin/addcaravansitevisitdetails`;
export const DELETE_CARAVAN_SITE_VISIT_DETAILS = `${baseURL}Admin/deletecaravansitevisitdetails`;
export const GET_CARAVAN_SITE_VISIT_DETAILS = `${baseURL}Admin/getcaravansitevisitcriteriabyid`;
export const GET_CARAVAN_CERTIFICATE_NUMBER = `${baseURL}Admin/getcaravancertificatenumber`;

export const GET_ACCOMODATION_REGISTRATIONS = `${baseURL}Admin/getaccomodationregistrationbysearch`;
export const UPDATE_ACCOMODATION_REGISTRATION_VERIFICATION = `${baseURL}Admin/updateaccomodationverification`;
export const UPDATE_ACCOMODATION_DOCUMENT_VERIFICATION = `${baseURL}Admin/updateaccomodationdocumentverification`;
export const GET_ACCOMODATION_REGISTRATION_BY_ID = `${baseURL}Applicant/getaccomodationregistrationbyid`;
export const GET_ACCOMODATION_DOCUMENTS_BY_ID = `${baseURL}Applicant/getaccomodationdocumentsbyid`;
export const ADD_ACCOMODATION_SITE_VISIT_DETAILS = `${baseURL}Admin/addaccomodationsitevisitdetails`;
export const DELETE_ACCOMODATION_SITE_VISIT_DETAILS = `${baseURL}Admin/deleteaccomodationsitevisitdetails`;
export const GET_ACCOMODATION_SITE_VISIT_DETAILS = `${baseURL}Admin/getaccomodationsitevisitcriteriabyid`;
export const GET_ACCOMODATION_CERTIFICATE_NUMBER = `${baseURL}Admin/getaccomodationcertificatenumber`;

export const DOWNLOAD_FILE = `${baseURL}Admin/downloadfile`;

export const GET_REGION_WISE_DASHBOARD = `${baseURL}Admin/getregionwisedashboard`;
export const GET_TOUR_TYPE_WISE_DASHBOARD = `${baseURL}Admin/gettourtypewisedashboard`;
export const GET_TOUR_TYPE_WISE_REGION_WISE_DASHBOARD = `${baseURL}Admin/gettourtypewiseregionwisedashboard`;
export const GET_REGION_WISE_DASHBOARD_FOR_WATER = `${baseURL}Admin/getregionwisedashboardforwateracccaravan`;
export const GET_TOUR_TYPE_WISE_DASHBOARD_FOR_WATER = `${baseURL}Admin/gettourtypewisedashboardforwateracccaravan`;
export const GET_TOUR_TYPE_WISE_REGION_WISE_DASHBOARD_FOR_WATER = `${baseURL}Admin/gettourtypewiseregionwisedashboardforwateracccaravan`;

export const GET_AGRO_TOUR_REGISTRATION_REPORT = `${baseURL}Admin/getagrotourregistrationreport`;
export const GET_ADVENTURE_TOUR_REGISTRATION_REPORT = `${baseURL}Admin/getadventuretourregistrationreport`;
export const GET_CARAVAN_REGISTRATION_REPORT = `${baseURL}Admin/getcaravanregistrationreport`;
export const GET_ACCOMODATION_REGISTRATION_REPORT = `${baseURL}Admin/getaccomodationregistrationreport`;
export const GET_APPROVED_AGRO_TOUR_REGISTRATION_REPORT = `${baseURL}Admin/getapprovedagrotourregistrationreport`;
export const GET_APPROVED_ADVENTURE_TOUR_REGISTRATION_REPORT = `${baseURL}Admin/getapprovedadventuretourregistrationreport`;
export const GET_APPROVED_CARAVAN_REGISTRATION_REPORT = `${baseURL}Admin/getapprovedcaravanregistrationreport`;
export const GET_APPROVED_ACCOMODATION_REGISTRATION_REPORT = `${baseURL}Admin/getapprovedaccomodationregistrationreport`;
export const GET_APPROVED_SITE_VISIT_AGRO_REPORT = `${baseURL}Admin/getapprovedsitevisitagroreport`;
export const GET_APPROVED_SITE_VISIT_ADVENTURE_REPORT = `${baseURL}Admin/getapprovedsitevisitadventurereport`;
export const GET_APPROVED_SITE_VISIT_CARAVAN_REPORT = `${baseURL}Admin/getapprovedsitevisitcaravanreport`;
export const GET_APPROVED_SITE_VISIT_ACCOMODATION_REPORT = `${baseURL}Admin/getapprovedsitevisitaccomodationreport`;
export const GET_SITE_VISIT_REMINDER_AGRO_REPORT = `${baseURL}Admin/getsitevisitreminderagroreport`;
export const GET_SITE_VISIT_REMINDER_ADVENTURE_REPORT = `${baseURL}Admin/getsitevisitreminderadventurereport`;
export const GET_SITE_VISIT_REMINDER_CARAVAN_REPORT = `${baseURL}Admin/getsitevisitremindercaravanreport`;
export const GET_SITE_VISIT_REMINDER_ACCOMODATION_REPORT = `${baseURL}Admin/getsitevisitreminderaccomodationreport`;
export const GET_RENEWED_AGRO_TOUR_REGISTRATION_REPORT = `${baseURL}Admin/getrenewedagrotourregistrationreport`;
export const GET_RENEWED_ADVENTURE_TOUR_REGISTRATION_REPORT = `${baseURL}Admin/getrenewedadventuretourregistrationreport`;
export const GET_RENEWED_CARAVAN_REGISTRATION_REPORT = `${baseURL}Admin/getrenewedcaravanregistrationreport`;
export const GET_RENEWED_ACCOMODATION_REGISTRATION_REPORT = `${baseURL}Admin/getrenewedaccomodationregistrationreport`;

export const ADD_UPDATE_ACCOMODATION_CERTIFICATE = `${baseURL}Admin/addupdateaccomodationcertificate`;
export const GET_ACCOMODATION_CERTIFICATE_BY_ID = `${baseURL}Admin/getaccomodationcertificatebyid`;
export const ADD_UPDATE_CARAVAN_CERTIFICATE = `${baseURL}Admin/addupdatecaravancertificate`;
export const GET_CARAVAN_CERTIFICATE_BY_ID = `${baseURL}Admin/getcaravancertificatebyid`;
export const ADD_UPDATE_ADVENTURE_CERTIFICATE = `${baseURL}Admin/addupdateadventurecertificate`;
export const GET_ADVENTURE_CERTIFICATE_BY_ID = `${baseURL}Admin/getadventurecertificatebyid`;
export const ADD_UPDATE_AGRO_CERTIFICATE = `${baseURL}Admin/addupdateagrocertificate`;
export const GET_AGRO_CERTIFICATE_BY_ID = `${baseURL}Admin/getagrocertificatebyid`;

// *************************************************************************************************************************

export default LOGIN_URL;